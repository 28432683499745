import type { FC } from 'react';
import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { NetworkStatus } from 'apollo-client';
import { css } from '@compiled/react';

import Tooltip from '@atlaskit/tooltip/Tooltip';
import AkChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import AkChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import { Flex, Box, Anchor, xcss, Pressable, Grid } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import AKSpinner from '@atlaskit/spinner/spinner';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';

import { Tree } from '@confluence/tree';
import type { ItemId, TreeItem, RenderItemParams } from '@confluence/tree';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';
import { usePageContentId } from '@confluence/page-context';
import { scrollElementToViewCenter } from '@confluence/dom-helpers';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { focusedAriaCurrentValue } from '@confluence/page-tree/entry-points/focusedAriaCurrentValue';
import {
	BLOG_TREE_EXPERIENCE,
	BLOG_TREE_PAGINATION_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import { INDENT_PER_LEVEL, CHEVRON_SIZE, VIRTUAL_ROOT_ID } from './treeParameters';
import type { BlogTreeItem } from './BlogTreeRootComponent';
import { BLOG_TREE_METRIC } from './perf.config';

const i18n = defineMessages({
	draftLozengeText: {
		id: 'blog-tree.status.draft',
		defaultMessage: 'DRAFT',
		description: 'User has started a blog but has not published it yet',
	},
	untitledDraftText: {
		id: 'blog-tree.untitled-draft',
		defaultMessage: 'Untitled',
		description: 'A placeholder for drafts that have no title',
	},
	loadMoreButton: {
		id: 'blog-tree.load-more-button',
		defaultMessage: 'Show more blogs',
		description: 'button to load more blogs in the blog tree',
	},
});

const stylizedLoadingSpinner = xcss({
	display: 'flex',
	flexShrink: 1,
	flexGrow: 1,
	flexBasis: '100%',
	justifyContent: 'center',
	marginTop: 'space.100',
});

const titleStylesNav3 = xcss({
	display: 'inline-block',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	opacity: 1,
	color: 'color.text.subtle',
	fontWeight: '400',
	paddingLeft: 'space.050',
});

const titleStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	opacity: 1,
});

const draftTitleStyles = xcss({
	opacity: '0.7', // Drafts should be 70% opacity
});

const selectedTitleStyles = xcss({
	color: 'color.text.selected',
});

const bulletPointStyles = xcss({
	width: '15px',
	marginLeft: 'space.100',
	fontSize: '12pt',
	color: 'color.text.subtlest',
});

const chevronIconStyles = xcss({
	color: 'color.text.subtlest',
	height: '24px',
});

const emojiWrapperStyles = xcss({
	display: 'flex',
	flexShrink: 0,
	alignItems: 'center',
});

const indentBlogpostItemStyles = xcss({
	paddingLeft: 'space.200',
	width: '100%',
});

const blogTreeLinkItemStyles = xcss({
	height: '36px',
	minHeight: '24px',
	width: '100%',
	backgroundColor: 'color.background.neutral.subtle',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	boxSizing: 'border-box',
	borderRadius: '3px',
	border: 'none',
	margin: 'space.0',
	textDecoration: 'none',
	paddingLeft: 'space.100',
	paddingRight: 'space.050',
	overflow: 'hidden',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		textDecoration: 'none',
	},
});

const selectedBlogTreeItemStyles = xcss({
	backgroundColor: 'color.background.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
});

const loadMoreButtonStylesNav3 = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	marginTop: 'space.100',
	marginBottom: 'space.100',
	borderRadius: '3px',
	fontWeight: 500,
	height: '24px',
	width: '100%',
	backgroundColor: 'color.background.accent.gray.subtlest',
	color: 'color.text.accent.gray.bolder',
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
});

const loadMoreButtonStyles = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.100',
});

const blogpostIndentationStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'li li': {
		paddingLeft: token('space.300', '24px'),
		marginLeft: token('space.025', '2px'),
	},
});

type BlogTreeComponentProps = {
	spaceKey: string;
	blogTreeData: Record<ItemId, BlogTreeItem>;
	shouldShowLoadMoreButton: boolean;
	networkStatus: NetworkStatus;
	fetchMoreBlogs: () => void;
	loading: boolean;
	pageTreeFinishedLoading: boolean;
	isNav4Enabled?: boolean;
};

type LoadMoreButtonProps = {
	networkStatus: NetworkStatus;
	fetchMoreBlogs: () => void;
	loading: boolean;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isNav4Enabled: boolean;
};

const getItemTitle = (item: BlogTreeItem, isDraft: boolean): string | JSX.Element => {
	const untitledPlaceholder = isDraft ? <FormattedMessage {...i18n.untitledDraftText} /> : '';
	return item?.data?.title || untitledPlaceholder;
};

const getEmoji = (item: BlogTreeItem) => {
	const emojiId = item?.data?.emoji;

	return emojiId ? (
		<LazyEmojiComponentLoader
			emoji={emojiId}
			context="blogTree"
			height={14}
			wrapper={({ children }) => <Box xcss={emojiWrapperStyles}>{children}</Box>}
		/>
	) : null;
};

const BlogYearParentTreeLinkItem = ({
	year,
	item,
	onExpand = () => {},
	onCollapse = () => {},
	createAnalyticsEvent,
	isNav4Enabled,
}: {
	year: string | JSX.Element;
	item: TreeItem;
	onCollapse?: (item: ItemId) => void;
	onExpand?: (item: ItemId) => void;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isNav4Enabled?: boolean;
}) => {
	const { isExpanded } = item;

	const toggleCollapse = isExpanded ? onCollapse : onExpand;

	const handleClickBlogYear = () => {
		toggleCollapse(item.id);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: isExpanded ? 'collapsed' : 'expanded',
				actionSubject: 'blogTree',
				actionSubjectId: 'blogYear',
				source: 'sideNavigation',
				attributes: {
					year,
				},
			},
		}).fire();
	};

	if (isNav4Enabled) {
		return (
			<ExpandableMenuItem isDefaultExpanded>
				<ExpandableMenuItemTrigger onClick={handleClickBlogYear}>
					<Box xcss={titleStyles}>{year}</Box>
				</ExpandableMenuItemTrigger>
			</ExpandableMenuItem>
		);
	}

	return (
		<Anchor xcss={blogTreeLinkItemStyles} onClick={handleClickBlogYear} href="#">
			<Flex columnGap="space.050" alignItems="center">
				<Box xcss={chevronIconStyles}>
					{isExpanded ? (
						<AkChevronDownIcon label="" size={CHEVRON_SIZE} />
					) : (
						<AkChevronRightIcon label="" size={CHEVRON_SIZE} />
					)}
				</Box>
				<Box xcss={titleStylesNav3}>{year}</Box>
			</Flex>
		</Anchor>
	);
};

const BlogTreeLinkItem = ({
	spaceKey,
	title,
	item,
	isDraft,
	isSelected,
	loading,
	createAnalyticsEvent,
	pageTreeFinishedLoading,
	isBlogTreeInitialLoadRef,
	isNav4Enabled,
}: {
	spaceKey: string;
	title: string | JSX.Element;
	item: BlogTreeItem;
	isDraft: boolean;
	isSelected?: boolean;
	loading?: boolean;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	pageTreeFinishedLoading: boolean;
	isBlogTreeInitialLoadRef?: React.MutableRefObject<boolean>;
	isNav4Enabled?: boolean;
}) => {
	const emojiComponent = getEmoji(item);
	const originalURL = `${CONTEXT_PATH}${item?.data?.webui}`;
	const blogTreeItemRef = useRef(null);

	const bypassURL = getURLBypassingResumeDraftAction({
		url: originalURL,
		spaceKey,
		contentType: 'blogpost',
		editorVersion: item?.data?.editorVersion || '',
		contentId: item?.id,
	});

	useEffect(() => {
		// Essentially, we should only scroll to the selected blog tree item when:
		// Blog tree has finished loading ("!loading"), page tree has finished loading, AND this is the initial load of blog tree (isBlogTreeInitialLoadRef) and not a rerender from clicking load more
		if (
			isSelected &&
			!loading &&
			blogTreeItemRef.current &&
			isBlogTreeInitialLoadRef?.current &&
			pageTreeFinishedLoading
		) {
			scrollElementToViewCenter(blogTreeItemRef.current);

			isBlogTreeInitialLoadRef.current = false;
		}
	}, [loading, isSelected, pageTreeFinishedLoading, isBlogTreeInitialLoadRef]);

	const handleClickBlogTreeItem = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'blogTree',
				actionSubjectId: 'blogpost',
				source: 'sideNavigation',
				attributes: {
					createdYear: item?.data?.createdYear,
					isDraft,
				},
			},
		}).fire();
	};

	if (isNav4Enabled) {
		return (
			<MenuLinkItem
				testId="blog-tree-item"
				href={bypassURL}
				ref={isSelected ? blogTreeItemRef : null}
				onClick={handleClickBlogTreeItem}
				isSelected={isSelected}
				aria-current={isSelected ? focusedAriaCurrentValue : undefined} // this is required for SSR scrolling to selected blog tree item
			>
				<Grid
					columnGap="space.100"
					templateColumns={`${emojiComponent ? 'minmax(0, auto) ' : ''}${isDraft ? 'auto ' : ''}1fr`.trim()}
				>
					{emojiComponent}
					<Box xcss={[titleStyles, isDraft && draftTitleStyles]}>{title}</Box>
					{isDraft && (
						<span>
							<Lozenge appearance="default">
								<FormattedMessage {...i18n.draftLozengeText} />
							</Lozenge>
						</span>
					)}
				</Grid>
			</MenuLinkItem>
		);
	}

	return (
		<Anchor
			xcss={[blogTreeLinkItemStyles, isSelected && selectedBlogTreeItemStyles]}
			testId="blog-tree-item"
			href={bypassURL}
			ref={isSelected ? blogTreeItemRef : null}
			onClick={handleClickBlogTreeItem}
			aria-current={isSelected ? focusedAriaCurrentValue : undefined} // this is required for SSR scrolling to selected blog tree item
		>
			<Flex xcss={indentBlogpostItemStyles} columnGap="space.050" alignItems="center">
				<Box xcss={bulletPointStyles}>&bull;</Box>
				{emojiComponent}
				<Box
					xcss={[titleStylesNav3, isDraft && draftTitleStyles, isSelected && selectedTitleStyles]}
				>
					{title}
				</Box>
				{isDraft && (
					<Lozenge appearance="default">
						<FormattedMessage {...i18n.draftLozengeText} />
					</Lozenge>
				)}
			</Flex>
		</Anchor>
	);
};

const LoadingSpinner = () => (
	<Box xcss={stylizedLoadingSpinner}>
		<AKSpinner size={20} />
	</Box>
);

const LoadMoreButton: FC<LoadMoreButtonProps> = ({
	loading,
	networkStatus,
	fetchMoreBlogs,
	createAnalyticsEvent,
	isNav4Enabled,
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	if (loading && networkStatus === NetworkStatus.fetchMore) {
		return <LoadingSpinner />;
	}

	const handleLoadMoreBlogsClick = () => {
		experienceTracker.start({
			name: BLOG_TREE_PAGINATION_EXPERIENCE,
		});
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'blogTree',
				actionSubjectId: 'loadMoreButton',
				source: 'sideNavigation',
			},
		}).fire();
		fetchMoreBlogs();
	};

	if (isNav4Enabled) {
		return (
			<Box xcss={loadMoreButtonStyles}>
				<Button shouldFitContainer spacing="compact" onClick={handleLoadMoreBlogsClick}>
					<FormattedMessage {...i18n.loadMoreButton} />
				</Button>
			</Box>
		);
	}

	return (
		<Pressable xcss={loadMoreButtonStylesNav3} onClick={handleLoadMoreBlogsClick}>
			<FormattedMessage {...i18n.loadMoreButton} />
		</Pressable>
	);
};

export const BlogTreeComponent: FC<BlogTreeComponentProps> = ({
	spaceKey,
	blogTreeData,
	shouldShowLoadMoreButton,
	networkStatus,
	fetchMoreBlogs,
	loading,
	pageTreeFinishedLoading,
	isNav4Enabled = false,
}) => {
	const [contentIdUserIsViewing] = usePageContentId();
	const [collapsedYears, setCollapsedYears] = useState<ItemId[]>([]);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isBlogTreeInitialLoadRef = useRef<boolean>(true);

	const blogTreeDataWithCollapsedYears = Object.keys(blogTreeData).reduce(
		(acc, key) => {
			acc[key] = {
				...blogTreeData[key],
				isExpanded: !collapsedYears.includes(key),
			};
			return acc;
		},
		{} as Record<string, BlogTreeItem>,
	);

	const onCollapseYear = useCallback(
		(itemId: ItemId) => {
			if (!collapsedYears.includes(itemId)) {
				setCollapsedYears([...collapsedYears, itemId]);
			}
		},
		[collapsedYears],
	);

	const onExpandYear = useCallback(
		(itemId: ItemId) => {
			if (collapsedYears.includes(itemId)) {
				setCollapsedYears(collapsedYears.filter((id) => itemId !== id));
			}
		},
		[collapsedYears],
	);

	const renderItem = ({ item, onExpand, onCollapse }: RenderItemParams<BlogTreeItem>) => {
		const isDraft = item?.data?.status === 'draft';
		const isSelected = item?.id === contentIdUserIsViewing; //item is selected if the content id matches the blog's id
		const title = getItemTitle(item, isDraft);

		return (
			<Tooltip content={title} position="top" delay={750} hideTooltipOnMouseDown>
				{item.data && item.hasChildren ? ( // only years are parent items for blog tree
					<BlogYearParentTreeLinkItem
						year={title}
						item={item}
						onExpand={onExpand}
						onCollapse={onCollapse}
						createAnalyticsEvent={createAnalyticsEvent}
						isNav4Enabled={isNav4Enabled}
					/>
				) : (
					<BlogTreeLinkItem
						title={title}
						item={item}
						isDraft={isDraft}
						spaceKey={spaceKey}
						isSelected={isSelected}
						loading={loading}
						createAnalyticsEvent={createAnalyticsEvent}
						pageTreeFinishedLoading={pageTreeFinishedLoading}
						isBlogTreeInitialLoadRef={isBlogTreeInitialLoadRef}
						isNav4Enabled={isNav4Enabled}
					/>
				)}
			</Tooltip>
		);
	};

	return loading && networkStatus === NetworkStatus.loading ? (
		<LoadingSpinner />
	) : (
		<div css={isNav4Enabled && blogpostIndentationStyle}>
			<Tree<BlogTreeItem>
				tree={{
					rootId: VIRTUAL_ROOT_ID,
					items: blogTreeDataWithCollapsedYears,
				}}
				isDragEnabled={false}
				renderItem={renderItem}
				onCollapse={onCollapseYear}
				onExpand={onExpandYear}
				indentPerLevel={INDENT_PER_LEVEL}
			/>
			{shouldShowLoadMoreButton && (
				<LoadMoreButton
					networkStatus={networkStatus}
					loading={loading}
					fetchMoreBlogs={fetchMoreBlogs}
					createAnalyticsEvent={createAnalyticsEvent}
					isNav4Enabled={isNav4Enabled}
				/>
			)}
			<PageSegmentLoadEnd metric={BLOG_TREE_METRIC} />
			<ExperienceSuccess name={BLOG_TREE_EXPERIENCE} />
		</div>
	);
};
